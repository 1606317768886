import { Injectable, inject } from '@angular/core';
import { type ActivatedRouteSnapshot, type RouterStateSnapshot, type UrlTree, Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable()
export class PermissionsGuard {
  private readonly userService = inject(UserService);
  private readonly router = inject(Router);

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const permissions: string[] = next.data.permissions;

    const canActivate = !permissions.find((perm) => !this.userService.permissions[perm]);

    if (!canActivate) {
      this.router.navigate(['']);
    }

    return canActivate;
  }
}
